let Loader = {

    show: function () {
        $("#loader").show();
    },

    hide: function () {
        $("#loader").hide();
    }
};

$(document).ready(function () {

    $("img.lazy").Lazy({
        // beforeLoad: function(element) {
        //     console.log("start loading " + element.prop("tagName"));
        // },
        visibleOnly: true,
        effectTime: 100
    });

    $('a[href^="#"]').click(function () {
        let target = $(this).attr("data-link");
        $([document.documentElement, document.body]).animate({scrollTop: $("#" + target).offset().top}, 400);
    });

    //flat
    $(".date").flatpickr({
        dateFormat: "Y-m-d",
        locale: "pl",
        altInput: false,
        altFormat: "d.m.Y",
        disableMobile: true
    });

    $(".setFavorites").off().on('click', function(e){
        e.preventDefault();
        let id = $(this).attr("data-offer-id");
        $.ajax({
            url:"/setFavorites",
            method:"POST",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data:{id:id},
            success:function(data){
                let stat = (data.status === 'add') ? 'favorite' : 'favorite_border';
                $('a[data-offer-id="'+ id +'"] span').html(stat);
            }
        });
    });

    $('.sendOfferApplication').click(function(e) {
        e.preventDefault();
        $('.modal-body-title').text('');
        let dataId = $(this).attr("data-id");
        //console.log(dataId);
        $('#applicationForm').modal('show');

        $.ajax({
            url: '/getOfferById/' + dataId,
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            beforeSend: function() {
                Loader.show();
            }
        })
            .done(function(response){
                Loader.hide();
                $.each(response, function(i, offer){
                    $('.modal-body-title').text(offer.title);
                    $('#InputOffer').val(offer.id);
                });
                $('#applicationForm').modal('show');

            })
            .fail(function(){
                $('#applicationForm').modal('hide');
            });

    });

    $('#sendApplication').submit(function(e){
        e.preventDefault();

        $.ajax({
            data: $('#sendApplication').serialize(),
            url: "/sendApplyJob",
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            beforeSend: function() {
                Loader.show();
            }
        })
            .done(function(response){
                Loader.hide();
                $('#sendApplication').trigger("reset");
                $('#applicationForm').modal('hide');

                //console.log(response);

                Swal.fire({
                    position: 'center',
                    icon: response.status,
                    title: response.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
                location.reload();
            })
            .fail(function(response){
                console.log('Error:', response);
            });

    });

    $('.sendNoneOfferApplication').click(function(e) {
        e.preventDefault();
        $('#applicationHomeModal').modal('show');
    });

    $('#sendNoneApplicationForm').submit(function(e){
        e.preventDefault();

        $.ajax({
            data: $('#sendNoneApplicationForm').serialize(),
            url: "/sendApplyJobHome",
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            beforeSend: function() {
                Loader.show();
            }
        })
    .done(function(response){
                Loader.hide();
                $('#sendNoneApplicationForm').trigger("reset");
                $('#applicationHomeModal').modal('hide');

                Swal.fire({
                    position: 'center',
                    icon: response.status,
                    title: response.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
                location.reload();
            })
            .fail(function(response){
                console.log('Error:', response);
            });

    });
});
